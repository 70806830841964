<!--
 * @Author: black9 lcl904624834@live.com
 * @Date: 2022-09-21 15:41:12
 * @LastEditors: black9 lcl904624834@live.com
 * @LastEditTime: 2022-12-13 15:54:15
 * @FilePath: /haimakid-xinao/src/views/backage/study-status/list.vue
-->
<template>
  <div style="mini-height: 600px">
    <div class="container mx-auto my-5">
      <el-card>
        <div class="flex justify-between items-center">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>班级学习状态</el-breadcrumb-item>
            <el-breadcrumb-item>
              <el-dropdown>
                <span class="el-dropdown-link">{{ chapter.name }} <i class="el-icon-arrow-down el-icon--right"></i></span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item :class="[item.id === chapter.id?'dropdown-text-active': '']" v-for="item in dropdownList" :key="item.id" @click.native="checkChapter(item)">{{ item.name }}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </el-breadcrumb-item>
          </el-breadcrumb>
          <div>
            <el-button icon="el-icon-refresh" type="success" size="mini" @click="init">刷新</el-button>
            <el-button
              icon="el-icon-back"
              type="primary"
              size="mini"
              @click="$router.go(-1)"
              >返回</el-button
            >
          </div>
        </div>
        <p class="my-4 text-xl">班级：{{ schoolClass.name }}</p>
        <el-table
          ref="table"
          :data="
            columns.filter(
              (data) =>
                !search ||
                data.name.toLowerCase().includes(search.toLowerCase())
            )
          "
          height="500"
          @sort-change="changeTableSort"
        >
          <el-table-column 
            v-for="(item, index) in header" 
            :key="index"
            :property="item.key"
            :prop="item.key" 
            :label="item.label"
            :fixed="item.key === 'name' ? true : false"
            :width="item.key === 'name' ? 120 : 130"
            :sortable="item.key === 'name' ? false : 'custom'"
          >
            <template v-if="item.key === 'name'" #header>
              <el-input v-model="search" size="mini" placeholder="搜索学生"></el-input>
            </template>
            <template #default="scope">
              <template v-if="item.key === 'name'">
                <div style="text-align: center">{{ scope.row[scope.column.property] }}</div>
              </template>
              <template v-else>
                <template v-if="scope.row[scope.column.property]">
                  <div v-if="scope.row[scope.column.property].isFinish">
                  <el-tag type="success" effect="dark" size="medium">已完成</el-tag>
                    <el-tooltip content="查看代码" placement="top">
                      <el-button 
                        v-if="scope.row[scope.column.property].type === GLOBAL.homework || scope.row[scope.column.property].type === GLOBAL.problem" 
                        type="primary" size="mini"
                        icon="el-icon-tickets"
                        class="ml-1"
                        @click="getStudentCode(scope.row[scope.column.property].studentId, scope.column.property)"
                      ></el-button>
                    </el-tooltip>
                  </div>
                  <el-tag v-else type="info" effect="dark" size="medium">待完成</el-tag>
                </template>
              </template>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
    </div>
    <el-dialog title="学生代码" :visible.sync="codeDialogStatus">
      <div class="flex justify-between text-lg p-2">
        <span>章节：{{ chapter.name }}</span>
        <span>学生：{{ currentRow.name }}</span>
      </div>
      <ace-editor
        ref="ace"
        :config="aceEditorConfig"
        :content="code"
        style="height: calc(100% - 40px); overflow: auto"
      ></ace-editor>
    </el-dialog>
  </div>
</template>

<script>
import AceEditor from "../../chapter/components/AceEditor.vue";
import { getChapterStudyStatus, getStudentFinishCode } from "@/api/backage";
export default {
  name: "StudyStatusList",
  components: {
    AceEditor,
  },
  data() {
    return {
      schoolClass: {
        id: 0,
        name: "",
      },
      chapter: {
        id: 0,
        name: "",
      },
      header: [],
      columns: [],
      search: "",
      codeDialogStatus: false,
      aceEditorConfig: {
        maxLines: 200,
        minLines: 15,
      },
      code: '',
      currentRow: {
        name: '',
      },
      dropdownList: [],
      loading: false,
    };
  },
  watch: {
    $route() {
      this.chapter.id = Number(this.$route.params.sid)
      this.schoolClass.id = Number(this.$route.params.cid)
      this.init()
    }
  },
  mounted() {
    this.schoolClass.id = this.$route.params.cid;
    this.chapter.id = this.$route.params.sid;
    this.init();
  },
  methods: {
    init() {
      if (this.loading === false) {
        this.loading = true;
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        getChapterStudyStatus({
          class_id: this.schoolClass.id,
          chapter_id: this.chapter.id,
        }).then((res) => {
          this.header = res.data.header;
          this.columns = res.data.columns;
          this.chapter = res.data.chapter;
          this.schoolClass = res.data.class;
          this.dropdownList = res.data.chapterList;
          setTimeout(() => {
            this.$nextTick(() => {
              this.$refs.table.doLayout();
            })
          })
        }).finally(() => {
          loading.close();
          this.loading = false;
        });
      }
      
    },
    changeTableSort(e) {
      const prop = e.prop
      // 如果按降序
      if (e.order === 'descending') {
        //根据需要对字段进行写排序
        this.columns = this.columns.sort((a, b) => {
          return a[prop].isFinish - b[prop].isFinish
        })
      } else { 
        this.columns = this.columns.sort((a, b) => {
          return b[prop].isFinish - a[prop].isFinish
        })
      }
    },
    checkChapter(row) {
      this.$router.push({ name: 'StudyStatusList', params: { sid: row.id, cid: this.schoolClass.id }})
    },
    getStudentCode(studentId, sectionId) {
      getStudentFinishCode({ student_id: studentId, section_id: sectionId }).then((res) => {
        this.viewCode(res.data.code)
      })
    },
    viewCode(code) {
        this.codeDialogStatus = true;
        this.$nextTick(() => {
          this.$refs.ace.setContent(code);
        })
    }
  },
};
</script>

<style>
</style>